.iconTop {
  position: fixed;
  width: auto;
  left: 90%;
  bottom: 30px;
  font-size: 3rem;
  z-index: 1;
  cursor: pointer;
}

.ejectIcon {
  margin-top: 4px;
}
