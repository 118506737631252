/* general */
.margin {
  margin: 0.7em;
}

.smallMargin {
  margin: 0.3em;
}

.main {
  min-height: 100vh;
  height: 100vh;
  margin: auto;
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  min-height: 65vh;
}

.logo {
  margin: 1em;
}

.tr_container {
  height: 20vh;
  max-height: 25vh;
}

.header {
  flex-grow: 1;
  max-height: 35vh;
  max-width: 100vw;
}

.headerText {
  display: flex;
  flex-direction: column;
}

.title {
  padding: 0.4em 0.3em;
  overflow-x: hidden;
}

.containerAlign {
  display: flex;
  align-items: center;
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #bebebe;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.logout {
  display: flex;
  gap: 1rem;
  margin: 1em;
}

.headerRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 2em;
  overflow-x: hidden;
}

.toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  max-height: 25vh;
}

/* login page */
.loggedButtons {
  display: flex;
  flex-flow: row wrap;

  align-items: center;
  justify-content: center;
}

/* same for both ADD and EDIT pages */
.addEditForm {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.addEditButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wordsInputMargin {
  margin: 0.1em 0.2em;
}

.upperRowSelection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.showHideChapters {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.addWordColumns {
  display: flex;
  flex-direction: column;
  flex-basis: 45%;
  min-width: 7em;
}

.addWordsRows {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.collocRows {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0.2em 0.8em;
}

/* TABLES */
.table {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1000px;
}

tr.expandable>td {
  width: 50%;
  text-align: center;
  border-bottom: 1px solid rgba(221, 221, 221, 0.5);
  padding: 0;
}

/* just to keep words max to width (empty row) */
tr>td.fifty {
  width: 50%;
}

/* centered buttons on /topic /chapter in a column */
td.centeredButton {
  text-align: center;
}

/* divider after each row */
th,
tr.tr_data>td {
  border-bottom: 1px solid #ddd;
}

/* TRANSLATION COMPONENT(s) */
.container {
  margin: 1em 0em;
  max-width: 1000px;
}

.marginLeft {
  margin-left: 1em;
}

.paperTranslator {
  max-height: 40vh;
  min-height: 15vh;
  height: 17vh;
}

.relatedTopic {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.upperDiv {
  max-height: 12vh;
  height: 6vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.upperDivItem {
  flex-basis: 40%;
}

.bottomDiv {
  max-height: 40vh;
  height: auto;
  min-height: 9vh;
  display: flex;
  justify-content: center;
}

.bottomDivCol {
  min-height: 5vh;
  height: auto;
}

.divider {
  flex-grow: 0;
  flex-shrink: 1;
}

.bottomSubDiv {
  flex-grow: 2;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
}

.translatorInputLeft {
  margin: 0.2em 0.3em 0em 0.5em;
}

.translatorInputRight {
  margin: 0.2em 0.5em 0em 0.3em;
}

.translatorInputItem {
  min-height: 18px;
  height: 26px;
  max-height: 52px;
  margin: 0.1em 0em 0.2em 0em;
}

.bottomSubDivRow {
  margin-left: 0.2em;
  margin-right: 0.2em;
  min-height: 48px;
  display: flex;
  align-items: center;
}

.bottomSubDivRowItem {
  flex-grow: 2;
  flex-basis: 0;
}

.iconsColocCtn {
  display: flex;
  justify-content: space-between;
}

/* UPLOAD BUTTONS */

.uploadButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

/* errors */
.error {
  color: red;
  font-weight: 700;
}

@media only screen and (max-width: 1000px) {
  .table {
    overflow-x: scroll;
  }

  .hiddenUnder1000 {
    display: none;
  }

  .title-faculty span {
    display: none;
  }

  .title-faculty::after {
    content: "LF UK v HK";
  }

  .title-dictionary {
    overflow: hidden;
  }

}